import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../theme";
import PrintUdyamCertificate from "../../Components/PrintUdyamCertificate";
import { Helmet } from "react-helmet";
import { blogsApi } from "../../Api/Blog";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";

const PrintUdyam = ({title,description}) => {
   const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchBlog = async () => {
      setLoading(true);
  
      try {
        const data = await blogsApi.getAllBlogs();
        let blog = data?.blogs.find(
          (item) => item.title === "PRINT_UDYAM_CERTIFICATE"
        );
        const blogData = cleanBlogContent(blog);
        setBlogData(blogData || {});
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
      fetchBlog();
    }, []);
  return (
    <Box
      sx={{
        margin: "0 auto",
        bgcolor: Colors.white,
      }}
    >  {loading ? (
      <></>
    ) : (
       <Helmet>
        <title>{blogData?.content?.split("(")[0].replace(/-/g, "").trim() || "Print Udyam"}</title>
        <meta name="description" content={blogData?.content?.split("(")[1]?.replace(")", "").trim() ||"description"} />
      </Helmet>)}
      <Container
        maxWidth="lg"
        sx={{ py: 6, bgcolor: Colors.white }}
      >
        <PrintUdyamCertificate/>
      </Container>
    </Box>
  );
};

export default PrintUdyam;
