export const UdyamRegistrationConstants = {
  applicantName: "Enter the applicant's name as mentioned on the PAN CARD.",
  mobileNumber:
    "Enter the applicant's 10-digit mobile number without adding +91.",
  emailId:
    "Enter the applicant's Email Id. Udyam Registration Certificate will be shared on that Email.",
  socialCategory: " Select the social category of the applicant.",
  officeAddress: "Office Address Enter the applicant's plant address.",
  pinCode: "Enter the applicant's Office Pin Code",
  state: "Select the applicant's Office State",
  disttrict: "Select the applicant's Office District",
  organisationType:
    "Select the type of organization or business the applicant owns.",
  businessName: "Enter the applicant's Business Name",
  dateOfCommencement: "Enter the date when the business started.",
  businessActivity:
    "Select the main business activity of the applicant's enterprise.",
  additionalDetails:
    "Mention more details of the applicant's business such as Food Products manufacturer, Retail Trade of spices, Automobile company, Computer Programming, etc.",
  maleEmployees: "Enter the number of male of employees working in the firm.",
  femaleEmployees:
    "Enter the number of female of employees working in the firm.",
  otherEmployees: "Enter the number of Other of employees working in the firm.",
  bankAccountNumber: "Enter the applicant's bank account number.",
  ifscCode: "Enter the IFSC code of the applicant's bank account.",
  panCardNumber:
    "Enter the 10-digit unique alphanumeric number mentioned on the applicant's PAN CARD.",
    uamNumber:" Applicant need to enter his / her 12 digit UAM number. Uam number will be found on the certificate as indicated below.",
    udyamRegistrationNumber:"Applicant need to enter his udyam registration number. as mentioned on udyam certificate",
    detailsToBeUpdatedOnCertificate: "Enter the applicant's Details To Be Updated On Certificate.",
    TypeOfCancellation:"Applicant are required to enter reason for cancellation",
    email:"Provide a valid email ID for communication.",
    enquiryType:"Select Enquiry type"
};
