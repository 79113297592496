import PerformRequest from "..";

export const ApplicantsApi = {
    getAllApplicants: async (id) => {
      return await PerformRequest(`applicants`, "GET");
    },
    addApplicants: async (body) => {
      return await PerformRequest(`applicants`, "POST", body);
    },
    updateApplicant: async (applicantId, body) => {
      return await PerformRequest(`applicants/${applicantId}`, "PATCH", body);
    },
    deleteApplicants: async (applicantId) => {
      return await PerformRequest(`applicants/${applicantId}`, "DELETE");
    },
  };
  