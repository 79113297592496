import React, { useId } from "react";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import "./index.css";
import DOMPurify from "dompurify";

const CustomToolbar = ({ toolbarId, imageOption }) => (
  <div id={`${toolbarId}`}>
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1" title="Heading 1">
        H1
      </option>
      <option value="2" title="Heading 2">
        H2
      </option>
      <option value="3" title="Heading 3">
        H3
      </option>
      <option value="4" title="Heading 4">
        H4
      </option>
      <option value="5" title="Heading 5">
        H5
      </option>
      <option value="6" title="Heading 6">
        H6
      </option>
      <option value="" title="Normal">
        Normal
      </option>
    </select>
    <button className="ql-bold" title="Bold"></button>
    <button className="ql-italic" title="Italic"></button>
    <button className="ql-underline" title="Underline"></button>
    <button className="ql-strike" title="Strike"></button>
    <select className="ql-color" title="Text Color"></select>
    <select className="ql-background" title="Background Color"></select>
    <button className="ql-align" value="" title="Align Left"></button>
    <button className="ql-align" value="center" title="Align Center"></button>
    <button className="ql-align" value="right" title="Align Right"></button>
    <button className="ql-blockquote" title="Blockquote"></button>
    <button className="ql-code-block" title="Code Block"></button>
    <button className="ql-list" value="ordered" title="Ordered List"></button>
    <button className="ql-list" value="bullet" title="Bullet List"></button>
    <button className="ql-link" title="Insert Link"></button>
    <button className="ql-clean" title="Clear Formatting"></button>
    {imageOption ? (
      <button className="ql-image" title="image Formatting"></button>
    ) : null}
  </div>
);

const parseContent = (htmlContent) => {
  const cleanHTML = DOMPurify.sanitize(htmlContent);
  const parser = new DOMParser();
  const doc = parser.parseFromString(cleanHTML, "text/html");

  const firstImage = doc.querySelector("img")?.src || null;

  const textContent = doc.body.textContent || "";
  const truncatedText = textContent.slice(0, 100) + (textContent.length > 100 ? "..." : "");

  return { firstImage, truncatedText };
};

const TextEditor = ({
  textValue,
  setTextValue,
  displayValue,
  toolbarId = "toolbar",
  imageOption = false,
  disabled = false,
  isHomePage=false,
}) => {
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
    },
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "link",
    "image",
  ];
  const { firstImage, truncatedText } = isHomePage && displayValue ? parseContent(displayValue) : {};

  return (
    <>
      {displayValue ? (
        isHomePage ? (
          <div>
            {firstImage && (
              <img
                src={firstImage}
                alt="Blog content visual"
                style={{
                  maxHeight:'100px',
                  maxWidth: "100%",
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              />
            )}
            <p style={{marginBottom:'0px',marginTop:'8px'}}>{truncatedText}</p>
          </div>
        ) : (
          <ReactQuill
            className="custom-quill"
            style={{
              backgroundColor: "#F9FAFB", 
              border: "1px solid #D1D5DB",
              borderRadius: "8px",
              fontSize: "1rem",
              fontFamily: "'Roboto', sans-serif",
              color: "#1F2937",
              lineHeight: "1.6", 
              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
              overflowY: "auto",

            }}
            theme="bubble"
            value={displayValue}
            readOnly
          />
        )
      ) : (
        <div>
          <CustomToolbar toolbarId={toolbarId} imageOption={imageOption} />
          <ReactQuill
            style={{ height: "85%", overflow: "scroll" }}
            theme="snow"
            value={textValue}
            onChange={setTextValue}
            modules={modules}
            formats={formats}
            readOnly={disabled}
          />
        </div>
      )}
    </>
  );
};

export default TextEditor;
