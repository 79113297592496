import axios from "axios";

const PerformRequest = (url, method, body, params, ) => {
  const Token = localStorage.getItem("Token");
  const baseURL = 'https://opti-consultancy-61566002034.us-central1.run.app';

  if (!baseURL) {
    return Promise.reject(new Error("Base URL is not defined."));
  }

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Cross-Origin-Opener-Policy": "same-origin-allow-popups",
    Authorization: `bearer ${Token}`,
  };


  const api = axios.create({
    baseURL: baseURL,
    headers: headers,
  });

  return new Promise((resolve, reject) => {
    api
      .request({ method: method, url: url, data: body, params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          reject(new Error("Unauthorized: You need to log in."));
        } else {
          reject(error);
        }
      });
  });
};

export default PerformRequest;
