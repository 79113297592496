import { Box, Container, Typography, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import banner from "../../Assets/Banner/banner1.webp";
import { Colors } from "../../theme";
import { Helmet } from "react-helmet-async";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";
import { blogsApi } from "../../Api/Blog";

const CancellationRefundPolicy = ({ title, description }) => {
  const [blogData, setBlogData] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchBlog = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      let blog = data?.blogs.find((item) => item.title === "CANCELLATION_REFUND_POLICY");
      const blogData = cleanBlogContent(blog);
      setBlogData(blogData || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <Box
      sx={{
        margin: "0 auto",
        bgcolor: Colors.white,
      }}
    >
      {loading ? (
        <></>
      ) : (
        <Helmet>
          <title>
            {blogData?.content?.split("(")[0].replace(/-/g, "").trim() ||
              "Cancellation Refund Policy"}
          </title>
          <meta
            name="description"
            content={
              blogData?.content?.split("(")[1]?.replace(")", "").trim() ||
              "description"
            }
          />
        </Helmet>
      )}
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "300px",
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
            zIndex: 1,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: Colors.white,
            position: "relative",
            zIndex: 3,
          }}
        >
          Cancellation & Refund Policy
        </Typography>
      </Box>

      <Container maxWidth="lg" sx={{ py: 6, bgcolor: Colors.white }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Cancellation Policy
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} paragraph>
          We follow a <strong>NO CANCELLATION POLICY</strong>.
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} paragraph>
          Any order placed with us can be cancelled only if service delivery
          from our end is not possible. In cases where delivery from our end is
          not possible, refunds shall be allowed to the client after deducting
          internet and handling charges (Approximately: Rs 500/-).
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} paragraph>
          <strong>Note:</strong> Non-delivery of services due to any deficiency
          in documents and/or not providing OTP (One Time Password), or any
          other shortcoming or non-cooperation from the client side, which
          renders the delivery of services impossible on our end, shall not be
          treated as "failure of delivery of services from our end". No refunds
          shall be available in such cases as there is no lack of performance on
          our end.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Refund Policy
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} paragraph>
          We follow a <strong>NO REFUND POLICY</strong>.
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} paragraph>
          Refunds against any order placed with us can be claimed only if
          service delivery from our end is not possible. In such cases, refunds
          shall be allowed to the client after deducting internet and handling
          charges of Rs 500/-.
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} paragraph>
          <strong>Note:</strong> Non-delivery of services on account of any
          deficiency in documents and/or not providing OTP (One Time Password),
          or any other shortcoming or non-cooperation from the client side which
          renders delivery of services impossible on our end, shall not be
          treated as "failure of delivery of services from our end". Such cases
          are treated as non-cooperation from the client's end, and no refunds
          shall be available in such cases as there is no lack of performance on
          our end.
        </Typography>
      </Container>
    </Box>
  );
};

export default CancellationRefundPolicy;
