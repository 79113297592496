import React, { useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Container,
  List,
  ListItem,
  FormHelperText,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { States } from "../../Utils/IndianStates";
import Joi from "joi";
import { ApplicantsApi } from "../../Api/Applicant";
import { Colors } from "../../theme";
import Example1 from "../../Assets/UdyogPrintCertificate/EXAMPLE1.jpg";
import { UdyamRegistrationConstants } from "../../Utils/UdyamRegistrationConstants";
import { CaptchaKey } from "../../Utils/CaptchaKey";
const UdyamRegistrationForm = () => {
  const State = States;
  const [formData, setFormData] = useState({
    applicantName: "",
    mobileNumber: "",
    emailId: "",
    additionalInfo: {
      socialCategory: "",
      officeAddress: "",
      pincode: "",
      panCardNumber: "",
      state: "",
      district: "",
      organisationType: "",
      bankAccountNumber: "",
      ifscCode: "",
      businessName: "",
      businessActivity: "",
      dateOfCommencement: "",
      maleEmployees: "",
      femaleEmployees: "",
      otherEmployees: "",
      additionalDetails: "",
    },
  });
  const [category, setCategory] = useState("OMNH");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    applicantName: Joi.string().min(3).max(50).required().messages({
      "string.base": '"Applicant Name" should be a string.',
      "string.empty": '"Applicant Name" cannot be empty.',
      "string.min": '"Applicant Name" should have at least 3 characters.',
      "string.max": '"Applicant Name" should have at most 50 characters.',
      "any.required": '"Applicant Name" is required.',
    }),

    mobileNumber: Joi.string()
      .pattern(/^[0-9]{10}$/)
      .required()
      .messages({
        "string.base": '"Mobile Number" should be a string.',
        "string.empty": '"Mobile Number" cannot be empty.',
        "string.pattern.base": '"Mobile Number" must be a 10-digit number.',
        "any.required": '"Mobile Number" is required.',
      }),

    emailId: Joi.string()
      .pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)
      .required()
      .messages({
        "string.base": '"Email ID" should be a string.',
        "string.empty": '"Email ID" cannot be empty.',
        "string.pattern.base": '"Email ID" must be a valid email address.',
        "any.required": '"Email ID" is required.',
      }),
    additionalInfo: Joi.object({
      socialCategory: Joi.string().required().messages({
        "string.base": '"socialCategory" should be a string.',
        "string.empty": '"socialCategory" cannot be empty.',
        "any.required": '"socialCategory" is required.',
      }),
      officeAddress: Joi.string().min(5).required().messages({
        "string.base": '"Office Address" should be a string.',
        "string.empty": '"Office Address" cannot be empty.',
        "string.min": '"Office Address" should have at least 5 characters.',
        "any.required": '"Office Address" is required.',
      }),
      panCardNumber: Joi.string()
        .pattern(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
        .required()
        .messages({
          "string.base": '"PAN Card Number" should be a string.',
          "string.empty": '"PAN Card Number" cannot be empty.',
          "string.pattern.base":
            '"PAN Card Number" must be in the format: ABCDE1234F (5 letters, 4 numbers, 1 letter).',
          "any.required": '"PAN Card Number" is required.',
        }),
      pincode: Joi.string()
        .pattern(/^[0-9]{6}$/)
        .required()
        .messages({
          "string.base": '"Pincode" should be a string.',
          "string.empty": '"Pincode" cannot be empty.',
          "string.pattern.base": '"Pincode" must be a 6-digit number.',
          "any.required": '"Pincode" is required.',
        }),

      state: Joi.string().required().messages({
        "string.base": '"State" should be a string.',
        "string.empty": '"State" cannot be empty.',
        "any.required": '"State" is required.',
      }),

      district: Joi.string().required().messages({
        "string.base": '"District" should be a string.',
        "string.empty": '"District" cannot be empty.',
        "any.required": '"District" is required.',
      }),

      organisationType: Joi.string()
        .valid(
          "proprietorship",
          "partnership_firm",
          "hindu_undivided_family",
          "limited_liability_partnership",
          "private_limited",
          "public_limited",
          "one_person_company",
          "self_help_group",
          "government_department",
          "society",
          "trust",
          "other"
        )
        .required()
        .messages({
          "string.base": '"Organisation Type" should be a string.',
          "string.empty": '"Organisation Type" cannot be empty.',
          "any.required": '"Organisation Type" is required.',
          "any.only":
            '"Organisation Type" must be one of the following from drodown.',
        }),

      bankAccountNumber: Joi.string().min(10).required().messages({
        "string.base": '"Bank Account Number" should be a string.',
        "string.empty": '"Bank Account Number" cannot be empty.',
        "string.min":
          '"Bank Account Number" should have at least 10 characters.',
        "any.required": '"Bank Account Number" is required.',
      }),

      ifscCode: Joi.string().min(11).max(11).required().messages({
        "string.base": '"IFSC Code" should be a string.',
        "string.empty": '"IFSC Code" cannot be empty.',
        "string.min": '"IFSC Code" must be exactly 11 characters long.',
        "string.max": '"IFSC Code" must be exactly 11 characters long.',
        "any.required": '"IFSC Code" is required.',
      }),

      businessName: Joi.string().optional().messages({
        "string.base": '"Business Name" should be a string.',
      }),

      businessActivity: Joi.string()
        .valid("manufacturer", "service_provider", "traders")
        .required()
        .messages({
          "string.base": '"Business Activity" should be a string.',
          "string.empty": '"Business Activity" cannot be empty.',
          "any.required": '"Business Activity" is required.',
          "any.only":
            'Business Activity" must be one of the following from dropdown.',
        }),

      dateOfCommencement: Joi.string().isoDate().required().messages({
        "string.base": '"Date of Commencement" should be a string.',
        "string.empty": '"Date of Commencement" cannot be empty.',
        "string.isoDate":
          '"Date of Commencement" should be a valid ISO date format.',
        "any.required": '"Date of Commencement" is required.',
      }),

      maleEmployees: Joi.number().integer().optional().messages({
        "number.base": '"Male Employees" should be a number.',
        "number.integer": '"Male Employees" should be an integer.',
      }),

      femaleEmployees: Joi.number().integer().optional().messages({
        "number.base": '"Female Employees" should be a number.',
        "number.integer": '"Female Employees" should be an integer.',
      }),

      otherEmployees: Joi.number().integer().optional().messages({
        "number.base": '"Other Employees" should be a number.',
        "number.integer": '"Other Employees" should be an integer.',
      }),

      additionalDetails: Joi.string().optional().messages({
        "string.base": '"Additional Details" should be a string.',
      }),
    }).required(),
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const updatedValue = name === "panCardNumber" ? value.toUpperCase() : value;

    if (name in formData) {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : updatedValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        additionalInfo: {
          ...prev.additionalInfo,
          [name]: value,
        },
      }));
    }
    validateField(name, updatedValue);
  };
  const validateField = (name, value) => {
    let fieldSchema;

    if (name in formData) {
      fieldSchema = schema.extract(name);
    } else if (name in formData.additionalInfo) {
      fieldSchema = schema.extract("additionalInfo").extract(name);
    }

    if (fieldSchema) {
      const { error } = fieldSchema.validate(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error ? error.message : "",
      }));
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!recaptchaValue) {
    //   alert("Please complete the reCAPTCHA.");
    //   return;
    // }
    if (!validateFormData()) {
      alert("Please add correct information");
      return;
    }
    try {
      const postData = { ...formData, category };

      const res = await ApplicantsApi.addApplicants(postData);
      if (res?.message) {
        alert("Application submitted successfully");
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        alert("Failed to submit the application. Please try again later.");
      }
    } finally {
      setFormData({
        applicantName: "",
        mobileNumber: "",
        emailId: "",
        additionalInfo: {
          socialCategory: "",
          officeAddress: "",
          pincode: "",
          panCardNumber: "",
          state: "",
          district: "",
          organisationType: "",
          bankAccountNumber: "",
          ifscCode: "",
          businessName: "",
          businessActivity: "",
          dateOfCommencement: "",
          maleEmployees: "",
          femaleEmployees: "",
          otherEmployees: "",
          additionalDetails: "",
        },
      });
    }
  };

  const validateFormData = () => {
    const { error } = schema.validate(formData, { abortEarly: false });

    if (error) {
      const newErrors = {};

      error.details.forEach((detail) => {
        let field = detail.path.join(".");

        if (field.startsWith("additionalInfo.")) {
          field = field.replace("additionalInfo.", "");
        }

        newErrors[field] = detail.message;
      });

      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  };

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 700 }}
          textAlign="start"
        >
          Udyam Registration Online Form For 2025 / उद्यम पंजीकरण के लिए आवेदन
          करें
        </Typography>
      </Grid> */}
      <Grid item xs={12} sm={6} pl={{ xs: 2, lg: 0 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid
              container
              spacing={2}
              ml={2}
              mt={2}
              sx={{ border: `1px solid ${Colors.dividerColor}` }}
            >
              <Grid
                item
                xs={12}
                sx={{ bgcolor: Colors.secondary, p: "8px 16px!important" }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 700, color: Colors.white }}
                  textAlign="start"
                >
                  Udyam Registration 2025 - Application Form / उद्यम पंजीकरण के
                  लिए आवेदन करें
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ p: 2, backgroundColor: Colors.formBgColor, ml: 0, pl: 0 }}
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">1. Name of Owner*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Applicant Name"
                    name="applicantName"
                    value={formData.applicantName}
                    onChange={handleChange}
                    error={!!errors?.applicantName}
                    helperText={
                      errors?.applicantName ||
                      UdyamRegistrationConstants?.applicantName
                    }
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">2. Mobile Number*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    error={!!errors?.mobileNumber}
                    helperText={
                      errors?.mobileNumber ||
                      UdyamRegistrationConstants?.mobileNumber
                    }
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">3. E-mail ID*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Email ID"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    error={!!errors?.emailId}
                    helperText={
                      errors?.emailId || UdyamRegistrationConstants?.emailId
                    }
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">4. Social Category*</Typography>

                  <FormControl size="small" fullWidth>
                    <Select
                      name="socialCategory"
                      value={formData.additionalInfo.socialCategory}
                      onChange={handleChange}
                      error={!!errors?.socialCategory}
                      displayEmpty
                    >
                      <MenuItem value="">Select Social Category</MenuItem>
                      <MenuItem value="general">General</MenuItem>
                      <MenuItem value="obc">OBC</MenuItem>
                      <MenuItem value="sc">SC</MenuItem>
                      <MenuItem value="st">ST</MenuItem>
                    </Select>
                  </FormControl>
                  {errors?.socialCategory ? (
                    <FormHelperText error>
                      {errors?.socialCategory ||
                        UdyamRegistrationConstants.socialCategory}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      {UdyamRegistrationConstants.socialCategory}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">5. Office Address*</Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Office Address"
                    name="officeAddress"
                    value={formData.additionalInfo.officeAddress}
                    onChange={handleChange}
                    error={!!errors?.officeAddress}
                    helperText={
                      errors?.officeAddress ||
                      UdyamRegistrationConstants?.officeAddress
                    }
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">6. Pin COde*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Pincode"
                    name="pincode"
                    value={formData.additionalInfo.pincode}
                    onChange={handleChange}
                    error={!!errors?.pincode}
                    helperText={
                      errors?.pincode || UdyamRegistrationConstants.pinCode
                    }
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">7. State*</Typography>

                  <FormControl size="small" fullWidth>
                    <Select
                      name="state"
                      displayEmpty
                      value={formData.additionalInfo.state}
                      onChange={handleChange}
                      error={!!errors?.state}
                    >
                      <MenuItem value="">Select State</MenuItem>
                      {State?.map((stateObj, i) => (
                        <MenuItem key={stateObj?.state} value={stateObj?.state}>
                          {`${i + 1} ${stateObj?.state}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors?.state ? (
                    <FormHelperText error>{errors?.state}</FormHelperText>
                  ) : (
                    <FormHelperText>
                      {UdyamRegistrationConstants.state}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">8. District*</Typography>

                  <FormControl size="small" fullWidth>
                    <Select
                      name="district"
                      value={formData.additionalInfo.district}
                      onChange={handleChange}
                      error={!!errors?.district}
                      displayEmpty
                    >
                      <MenuItem value="">Select District</MenuItem>
                      {formData.additionalInfo.state &&
                        State?.find(
                          (stateObj) =>
                            stateObj.state === formData.additionalInfo.state
                        )?.districts?.map((district) => (
                          <MenuItem key={district} value={district}>
                            {district}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors?.district ? (
                    <FormHelperText error>{errors?.district}</FormHelperText>
                  ) : (
                    <FormHelperText>
                      {UdyamRegistrationConstants.disttrict}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    9. Type of Organisation*
                  </Typography>

                  <FormControl size="small" fullWidth>
                    <Select
                      name="organisationType"
                      value={formData.additionalInfo.organisationType}
                      onChange={handleChange}
                      error={!!errors?.organisationType}
                      displayEmpty
                    >
                      <MenuItem value="">Select Organisation</MenuItem>
                      <MenuItem value="proprietorship">Proprietorship</MenuItem>
                      <MenuItem value="partnership_firm">
                        Partnership Firm
                      </MenuItem>
                      <MenuItem value="hindu_undivided_family">
                        Hindu Undivided Family
                      </MenuItem>
                      <MenuItem value="limited_liability_partnership">
                        Limited Liability Partnership
                      </MenuItem>
                      <MenuItem value="private_limited">
                        Private Limited
                      </MenuItem>
                      <MenuItem value="public_limited">Public Limited</MenuItem>
                      <MenuItem value="one_person_company">
                        One Person Company
                      </MenuItem>
                      <MenuItem value="self_help_group">
                        Self Help Group
                      </MenuItem>
                      <MenuItem value="government_department">
                        Government Department
                      </MenuItem>
                      <MenuItem value="society">Society</MenuItem>
                      <MenuItem value="trust">Trust</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                  {errors?.organisationType ? (
                    <FormHelperText error>
                      {errors?.organisationType}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      {UdyamRegistrationConstants?.organisationType}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">10. Business Name*</Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Business Name"
                    name="businessName"
                    value={formData.additionalInfo.businessName}
                    onChange={handleChange}
                    error={!!errors?.businessName}
                    helperText={
                      errors?.businessName ||
                      UdyamRegistrationConstants?.businessName
                    }
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    11. Date of Commencement of Business*
                  </Typography>

                  <TextField
                    size="small"
                    fullWidth
                    type="date"
                    placeholder="Date of Commencement of Business"
                    name="dateOfCommencement"
                    value={formData.additionalInfo.dateOfCommencement}
                    onChange={handleChange}
                    error={!!errors?.dateOfCommencement}
                    helperText={
                      errors?.dateOfCommencement ||
                      UdyamRegistrationConstants?.dateOfCommencement
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    12. Main Business Activity*
                  </Typography>

                  <FormControl size="small" fullWidth>
                    <Select
                      name="businessActivity"
                      value={formData.additionalInfo.businessActivity}
                      onChange={handleChange}
                      error={!!errors?.businessActivity}
                      displayEmpty
                    >
                      <MenuItem value="">Select Business Activity</MenuItem>
                      <MenuItem value="manufacturer">Manufacturer</MenuItem>
                      <MenuItem value="service_provider">
                        Service Provider
                      </MenuItem>
                      <MenuItem value="traders">Traders</MenuItem>
                    </Select>
                  </FormControl>
                  {errors?.businessActivity ? (
                    <FormHelperText error>
                      {errors?.businessActivity}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      {UdyamRegistrationConstants?.businessActivity}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption">
                    13. Additional Details About Business*
                  </Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Additional Details About Business"
                    name="additionalDetails"
                    value={formData.additionalInfo.additionalDetails}
                    onChange={handleChange}
                    error={!!errors?.additionalDetails}
                    helperText={
                      errors?.additionalDetails ||
                      UdyamRegistrationConstants?.additionalDetails
                    }
                    multiline
                    rows={3}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="caption">14. Male*</Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Male"
                    name="maleEmployees"
                    value={formData.additionalInfo.maleEmployees}
                    onChange={handleChange}
                    error={!!errors?.maleEmployees}
                    helperText={
                      errors?.maleEmployees ||
                      UdyamRegistrationConstants?.maleEmployees
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption">15. Female*</Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Female"
                    name="femaleEmployees"
                    value={formData.additionalInfo.femaleEmployees}
                    onChange={handleChange}
                    error={!!errors?.femaleEmployees}
                    helperText={
                      errors?.femaleEmployees ||
                      UdyamRegistrationConstants?.femaleEmployees
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption">16. Other*</Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Other"
                    name="otherEmployees"
                    value={formData.additionalInfo.otherEmployees}
                    onChange={handleChange}
                    error={!!errors?.otherEmployees}
                    helperText={
                      errors?.otherEmployees ||
                      UdyamRegistrationConstants?.otherEmployees
                    }
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    17. Bank Account Number*
                  </Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Bank Account Number"
                    name="bankAccountNumber"
                    value={formData.additionalInfo.bankAccountNumber}
                    onChange={handleChange}
                    error={!!errors?.bankAccountNumber}
                    helperText={
                      errors?.bankAccountNumber ||
                      UdyamRegistrationConstants?.bankAccountNumber
                    }
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">18. IFSC Code*</Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="IFSC Code"
                    name="ifscCode"
                    value={formData.additionalInfo.ifscCode}
                    onChange={handleChange}
                    error={!!errors?.ifscCode}
                    helperText={
                      errors?.ifscCode || UdyamRegistrationConstants?.ifscCode
                    }
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    19. Pan Card Number*
                  </Typography>

                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Pan Card Number"
                    name="panCardNumber"
                    value={formData.additionalInfo.panCardNumber.toUpperCase()}
                    onChange={handleChange}
                    error={!!errors?.panCardNumber}
                    helperText={
                      errors?.panCardNumber ||
                      UdyamRegistrationConstants?.panCardNumber
                    }
                    variant="outlined"
                    required
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                sx={{ mt: 2 }}
              >
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} sm={6} pl={2}>
        <Grid
          item
          spacing={2}
          sx={{ border: `1px solid ${Colors.dividerColor}` }}
        >
          <Grid
            item
            xs={12}
            sx={{ bgcolor: Colors.secondary, p: "8px 16px!important" }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, color: Colors.white }}
              textAlign="start"
            >
              Udyam Registration 2025
            </Typography>
          </Grid>
          <Grid item sx={{ p: 2, backgroundColor: Colors.formBgColor,  }}>
            <img
              src={Example1}
              alt="Udyam Registration"
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UdyamRegistrationForm;
