import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProtechtedRoute = ({ allowedRoles }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const Navigate = useNavigate();

  const decodeToken = (token) => {
    try {
      const decodedJwt = JSON.parse(atob(token.split(".")[1]));
      return decodedJwt;
    } catch {
      return null;
    }
  };

  const validateAccess = () => {
    const token = localStorage.getItem("Token");
    const userRole = localStorage.getItem("Role");
    if (token) {
      const decodedJwt = decodeToken(token);
      if (decodedJwt && allowedRoles.includes(userRole)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (validateAccess()) {
      setIsLoggedIn(true);
      setIsAuthorized(true);
    } else {
      setIsLoggedIn(false);
      setIsAuthorized(false);
      Navigate("/login");
    }
  }, []);

  if (!isLoggedIn) {
    return null; 
  }

  if (!isAuthorized) {
    return <h1>Unauthorized Access</h1>; 
  }

  return <Outlet />;
};

export default ProtechtedRoute;
