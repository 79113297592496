import { Box, Container, Typography, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import banner from "../../Assets/Banner/banner1.webp";
import { Colors } from "../../theme";
import { Helmet } from "react-helmet";
import { blogsApi } from "../../Api/Blog";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";

const TermsConditions = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBlog = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      let blog = data?.blogs.find((item) => item.title === "TERMS_CONDITIONS");
      const blogData = cleanBlogContent(blog);
      setBlogData(blogData || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <Box
      sx={{
        margin: "0 auto",
        bgcolor: Colors.white,
      }}
    >
      {loading ? (
        <></>
      ) : (
        <Helmet>
          <title>
            {blogData?.content?.split("(")[0].replace(/-/g, "").trim() ||
              "Terms Condition"}
          </title>
          <meta
            name="description"
            content={
              blogData?.content?.split("(")[1]?.replace(")", "").trim() ||
              "description"
            }
          />
        </Helmet>
      )}
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "300px",
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
            zIndex: 1,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: Colors.white,
            position: "relative",
            zIndex: 3,
          }}
        >
          Terms and Conditions
        </Typography>
      </Box>

      <Container maxWidth="lg" sx={{ py: 6, bgcolor: Colors.white }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Terms and Conditions for MSME Registration
        </Typography>
        <Typography
          variant="body1"
          color={Colors.textSecondary}
          sx={{ letterSpacing: "0.5px" }}
          mb={4}
        >
          Thanks for visiting Msme Registration! Please go through these Terms
          and Conditions before using this website.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          The Right Conduct
        </Typography>
        <List sx={{ listStyleType: "none", padding: 0, mb: 4 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user acknowledges that to avail of our services and go through
              our website resources, the user needs to provide information such
              as name, contact details, email ID, etc. It is a part of our
              registration process that will allow clients to use our services.
              The user should provide valid information.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              Using services from means other than the Msme Registration
              provides is strictly prohibited. Any user should not use bots or
              any exploitive means to access our resources.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              Endeavors like false imitation, copying, duplicating, or
              exchanging our assets are strictly prohibited.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user will be solely responsible for any misfortunes or
              unfavorable outcomes led by his/her unproved or unconstitutional
              actions.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              We might provide some specialized resources like discussion
              forums, open visits, blog entries, newsgroups, newsletters,
              surveys, online journal remarks, etc. The user consents to not
              posting or dispersing any data that is defamatory, fake, bugging,
              illicit, or infringes any copyright, trademark, or patent rights.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              We have the sole right to remove or unroot any substance from the
              website that, in our judgment, doesn't comply with this agreement
              or violates any third-party rights.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Standard Pricing Policy
        </Typography>
        <List sx={{ listStyleType: "none", padding: 0, mb: 4 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              Msme Registration has set a standard price for all the services we
              provide so that clients of every category can avail of these
              services according to their business plan. The user will be
              provided with the base prices of the services on the website or by
              email. Prices may vary depending on territory, state, or the type
              and number of services.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The total fee paid by the client might vary due to government fee
              changes, or if there is a resubmission of forms due to rejection.
              The standard pricing policy will not apply in such cases.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              If the prices quoted are incorrect due to error, we will verify it
              and provide the correct price before the contract.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Timeline for Delivery of Services
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} mb={4}>
          We have mentioned the estimated time for the completion of a legal
          procedure on our website. The client should not consider these
          completion times as an agreed promise. The time of legal services
          depends on external factors like government offices and third-party
          vendors. Our company will not be responsible for any misfortunes or
          harms that are coincidental or brought by the user while utilizing our
          services.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Privacy and Security
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} mb={4}>
          The client's protection and safety are our top priority, which is why
          we have a privacy policy clarifying how we collect, analyze, use, and
          protect the client's personal data.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Copyright/Patent/Trademark
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} mb={4}>
          Everything accessible on the Msme Registration website, including
          logos, content, site name, and design, is licensed and protected by
          copyright law. Any misuse of these things is strictly prohibited
          unless approved by Msme Registration.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Suspension
        </Typography>
        <Typography variant="body1" mb={4} color={Colors.textSecondary}>
          We may suspend or terminate your access to some or all portions of our
          website with or without notifying you for actions against this
          agreement. We may erase data upon suspension or termination.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Order Cancellation and Refund Policy
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary}>
          The TaxationGrow team is committed to improving services and providing
          full satisfaction to clients. However, there may be cases where a
          client demands a refund for reasons such as:
        </Typography>
        <List sx={{ listStyleType: "none", padding: 0 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              They pay for the services but later decline to avail them.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              When the service is delayed due to human error or factors for
              which we are solely responsible.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              If clients find our services not up to the mark.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="body1" color={Colors.textSecondary}>
          In all of these cases, please send an email to
          TaxationGrow.in@gmail.com clarifying the reason for the refund. Our
          authorities will decide if your refund should be processed or not.
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary}>
          Please note that in cases outside our control, like delays from the
          government’s side, national holidays, or other factors, we shall not
          be responsible for any delays.
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary}>
          If your request for a refund is accepted, you will receive an email
          asking for the bank details such as account number and IFSC. We assure
          you that the refund will be granted within 2-3 business days.
        </Typography>

        <Typography
          variant="body1"
          color={Colors.textSecondary}
          sx={{ letterSpacing: "0.5px", mt: 2 }}
        >
          We welcome constructive feedback. For further queries, please reach
          out to us via email.
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsConditions;
