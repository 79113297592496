import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicTabs from "../../Components/DynamicTabs";
import UdyamRegistrationForm from "../../Components/UdyamRegistrationForm";
import ReRegistrationForm from "../../Components/ReRegistrationForm";
import UpdateUdyamCertificate from "../../Components/UpdateUdyamCertificate";
import PrintUdyamCertificate from "../../Components/PrintUdyamCertificate";
import CancelUdyamRegistration from "../../Components/CancelUdyamRegistration";
import UdyogPrintCertificate from "../../Components/UdyogPrintCertificate";
import SimpleCertificate from "../../Components/SimpleCertificate";
import HeroSection from "../../Components/HeroSection";
import BlogSection from "../../Components/BlogSection";
import Footer from "../../Components/Footer";
import { Colors } from "../../theme";
import FAQSection from "../../Components/FAQ";
import { Helmet } from "react-helmet";
import { blogsApi } from "../../Api/Blog";
import { parseTitleAndDescription } from "../../Utils/parseTitleAndDescription";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";

const Home = ({ title, description }) => {
  const tabOptions = [
    { label: "Udyam Registration Form", component: UdyamRegistrationForm },
    { label: "Udyam Re Registration", component: ReRegistrationForm },
    { label: "Update Udyam Certificate", component: UpdateUdyamCertificate },
    { label: "Print udyam certificate", component: PrintUdyamCertificate },
    { label: "CANCEL UDYAM REGISTRATION", component: CancelUdyamRegistration },
    { label: "Udyog print certificate", component: UdyogPrintCertificate },
    { label: "Sample Certificate", component: SimpleCertificate },
  ];
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBlog = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      let blog = data?.blogs.find((item) => item.title === "HOME_PAGE");
      const blogData = cleanBlogContent(blog);
      setBlogData(blogData || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <Helmet>
          <title>{blogData?.content?.split("(")[0].replace(/-/g, "").trim() || "mau MSME Registration"}</title>
          <meta name="description" content={blogData?.content?.split("(")[1]?.replace(")", "").trim() || "description"} />
        </Helmet>
      )}
      <Box
        sx={{
          margin: "0 auto",
          bgcolor: Colors.white,
        }}
      >
        <HeroSection />
        {/* <DynamicTabs options={tabOptions} /> */}
        <Container maxWidth="lg" sx={{ py: 6, bgcolor: Colors.white }}>
          <UdyamRegistrationForm />
        </Container>
        <BlogSection />
        <FAQSection />
      </Box>
    </div>
  );
};

export default Home;
