import React, { useEffect, useState } from "react";
import { ApplicantsApi } from "../../../Api/Applicant";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { Colors } from "../../../theme";

const ViewApplicants = () => {
  const [applicantsData, setApplicantsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const fetchApplicants = async () => {
    setLoading(true);
    try {
      const data = await ApplicantsApi.getAllApplicants();
      setApplicantsData(data?.applicants);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchApplicants();
  }, []);
  return (
    <Box
      sx={{
        bgcolor: Colors.background,
        minHeight: "100dvh",
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          alignItems: "start",
          justifyContent: "flex-start",
          flexGrow: 1,
          px: { xs: 2 },
          py: { xs: 2 },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "200px",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <TableContainer
              sx={{
                backgroundColor: Colors.white,
                border: `1px solid ${Colors.dividerColor}`,
                borderRadius: "8px",
                overflowX: "auto",
                overflowY: "hidden",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  marginBottom: "16px",
                  textAlign: "left",
                  mt: 2,
                  ml: 2,
                }}
              >
                Applicants List
              </Typography>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{ borderTop: `1px solid ${Colors.tableHeaderBorder}` }}
                  >
                    {["Name", "Email", "Mobile", "Actions"].map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: Colors.primary,
                          py: 1,
                          px: 2,
                          fontWeight: "600",
                          letterSpacing: "0.5px",
                          bgcolor: "#F9FBFC",
                          borderBottom: `1px solid ${Colors.tableHeaderBorder}`,
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicantsData.map((applicant, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        onClick={() => handleRowClick(index)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#F5F5F5" },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: Colors.primaryTextColor,
                            borderBottom: `1px solid ${Colors.dividerColor}`,
                          }}
                        >
                          {applicant.applicant_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: Colors.primaryTextColor,
                            borderBottom: `1px solid ${Colors.dividerColor}`,
                          }}
                        >
                          {applicant.email_id}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: Colors.primaryTextColor,
                            borderBottom: `1px solid ${Colors.dividerColor}`,
                          }}
                        >
                          {applicant.mobile_number}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: Colors.primaryTextColor,
                            borderBottom: `1px solid ${Colors.dividerColor}`,
                          }}
                        >
                          <IconButton size="small">
                            {expandedRow === index ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            padding: 0,
                            borderBottom:
                              expandedRow === applicant?.id
                                ? `1px solid ${Colors.dividerColor}`
                                : "none",
                          }}
                        >
                          <Collapse in={expandedRow === index} timeout="auto">
                            <Box
                              sx={{
                                padding: 2,
                                backgroundColor: "#F9FBFC",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Additional Info
                              </Typography>
                              <Table
                                size="small"
                                sx={{
                                  border: `1px solid ${Colors.tableHeaderBorder}`,
                                  borderRadius: "8px",
                                }}
                              >
                                <TableBody>
                                  {Object.entries(
                                    applicant.additional_info
                                  ).map(([key, value]) => (
                                    <TableRow key={key}>
                                      <TableCell
                                        sx={{
                                          color: "#3C3C3C",
                                          py: 1,
                                          px: 2,
                                          borderBottom: `1px solid ${Colors.tableHeaderBorder}`,
                                        }}
                                      >
                                        {key}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          color: "#3C3C3C",
                                          py: 1,
                                          px: 2,
                                          borderBottom: `1px solid ${Colors.tableHeaderBorder}`,
                                        }}
                                      >
                                        {value}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </Box>
  );
};

export default ViewApplicants;
