import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  Menu as MuiMenu,
  MenuItem,
  ListItem,
  Button,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Close, Menu } from "@mui/icons-material";
import logo from "../../Assets/Branding/navLogo.webp";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import theme, { Colors } from "../../theme";

const Navbar = ({ handleDrawerToggle, mobileOpen }) => {
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobileView = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const location = useLocation();
  const getMenuItemStyle = (path,smallScreen) => {
    return location.pathname === path
      ? { color: Colors.sidebarActiveItem, fontWeight: "500" }
      :smallScreen? { color: Colors.textPrimary }:{ color: Colors.white };
  };
  const token = localStorage.getItem("Token");
  let decoded = null;

  if (token) {
    try {
      decoded = jwtDecode(token);
    } catch (err) {
      console.error("Invalid token:", err);
    }
  }
  const storedUser = JSON.parse(localStorage.getItem("user"));

  const [Data, setData] = useState({
    name: storedUser?.email || "name",
    src: "",
    alt: storedUser?.email || "profile",
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: Colors.sidebarBackground,
        borderTop: "1px solid #e3e3e3",
        borderBottom: `1px solid ${Colors.secondary}`,
        height: "64px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.07)!important",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          px: "0px!important",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"16px"}
            sx={{ cursor: "pointer", pl: 2.5 }}
          >
            <img
              src={logo}
              alt="Stay management Logo"
              height={isMediumScreen ? 25 : 42}
              width={isMediumScreen ? 25 : 42}
              style={{ marginLeft: isMediumScreen ? "5px" : "24px" ,borderRadius:'8px'}}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {!isMobileView && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                sx={getMenuItemStyle("/dashboard")}
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </Button>

              <Button
                sx={getMenuItemStyle("/view-applicants")}
                onClick={() => navigate("/view-applicants")}
              >
                View Applicants
              </Button>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "auto",
              padding: "16px 20px",
              gap: "8px",
              cursor: "pointer",
            }}
            onClick={handleMenuOpen}
          >
            <Avatar
              src={Data?.src || "/signin"}
              alt={Data?.alt}
              sx={{
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Typography
              fontSize={"12px"}
              letterSpacing={"1%"}
              textAlign={"start"}
              fontWeight={"400"}
              lineHeight={"14px"}
              color={Colors.white}
            >
              {Data?.name}
            </Typography>
            <IconButton onClick={handleMenuOpen} sx={{ padding: "0px" }}>
              {menuOpen ? (
                <ArrowDropUp sx={{ color: Colors.secondary }} />
              ) : (
                <ArrowDropDown sx={{ color: Colors.secondary }} />
              )}
            </IconButton>
            {menuOpen && (
              <MuiMenu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                sx={{
                  position: "absolute",
                  top: "0px !important",
                  left: "16px !important",
                  zIndex: 1000,
                }}
              >
                {" "}
                <MenuItem
                  sx={getMenuItemStyle("/dashboard",true)}
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  sx={getMenuItemStyle("/view-applicants",true)}
                  onClick={() => navigate("/view-applicants")}
                >
                  View Applicants
                </MenuItem>
                <ListItem
                  sx={{
                    padding: "10px",
                    gap: "5px",
                  }}
                >
                  <Avatar
                    src={Data?.src || "/signin"}
                    alt={Data?.alt}
                    sx={{ width: "28px", height: "28px" }}
                  />
                  <Typography
                    fontSize={"12px"}
                    letterSpacing={"1%"}
                    textAlign={"start"}
                    fontWeight={"400"}
                    lineHeight={"14px"}
                    color={Colors.secondary}
                  >
                    {Data?.name}
                  </Typography>
                </ListItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    localStorage.removeItem("Token");
                    localStorage.removeItem("Role");
                    localStorage.removeItem("user");
                    navigate("/login");
                  }}
                >
                  Log Out
                </MenuItem>
              </MuiMenu>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
