import PerformRequest from "..";

export const blogsApi = {
    getAllBlogs: async () => {
      return await PerformRequest(`blogs`, "GET");
    },
    createBlog: async (body) => {
      return await PerformRequest(`blogs`, "POST", body);
    },
    updateBlog: async (id, body) => {
      return await PerformRequest(`blogs/${id}`, "PUT", body);
    },
    deleteBlog: async (id) => {
      return await PerformRequest(`blogs/${id}`, "DELETE");
    },
  };
  