import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Colors } from "../../theme";
import Logo from "../../Assets/Branding/navLogo.webp";
const tabOptions = [
  { label: "Udyam Registration Form", path: "/" },
  { label: "Udyam Re Registration", path: "/re-registration" },
  { label: "Update Udyam Certificate", path: "/update-udyam" },
  { label: "Print Udyam Certificate", path: "/print-udyam" },
  { label: "Cancel Udyam Registration", path: "/cancel-udyam" },
  { label: "Udyog Print Certificate", path: "/udyog-print" },
  { label: "Sample Certificate", path: "/sample-certificate" },
];

const HomeNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const isActiveTab = (path) => location.pathname === path;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#07038D",
          zIndex: 1100,
          alignItems: { xs: "", lg: "center" },
        }}
      >
        <Toolbar
          sx={{
            alignItems: { xs: "", lg: "center" },
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={Logo}
            alt="Company Logo"
            sx={{
              minWidth: "100px",
              maxWidth: "150px",
              height: "auto",
              borderRadius: 1,
              mr: { xs: 0, md: 3 },
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          />

          <Box sx={{ display: { xs: "none", lg: "flex" }, gap: 2 }}>
            {tabOptions.map((tab, index) => (
              <NavLink
                key={index}
                to={tab.path}
                style={({ isActive }) => ({
                  fontSize: "12px",
                  color: "white",
                  textDecoration: "none",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  backgroundColor: isActive ? "white" : "transparent",
                  color: isActive ? "#07038D" : "white",
                  fontWeight: isActive ? "bold" : "normal",
                })}
              >
                {tab.label}
              </NavLink>
            ))}
          </Box>

          <IconButton
            color={Colors.white}
            edge="end"
            sx={{ display: { lg: "none" } }}
            onClick={handleMenuOpen}
          >
            <MenuIcon sx={{ color: Colors.white }} />
          </IconButton>
          <Menu
            PaperProps={{
              sx: {
                width: "100vw",
                maxWidth: "100%",
                left: "0 !important",
                top: "56px !important",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
              },
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {tabOptions.map((tab, index) => (
              <MenuItem
                key={index}
                component={NavLink}
                to={tab.path}
                onClick={handleMenuClose}
                sx={{
                  backgroundColor: isActiveTab(tab.path)
                    ? Colors.secondary
                    : "transparent",
                  color: isActiveTab(tab.path)
                    ? Colors.white
                    : Colors.textPrimary,
                  fontWeight: isActiveTab(tab.path) ? "500" : "normal",
                }}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HomeNavbar;
