export const CleanBlogHeadContent = (data) => {
    return data.map(blog => {
      const cleanedContent = blog.content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
  
      return {
        content: cleanedContent,
        title: blog.title
      };
    });
  };
  