import "./App.css";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ThemeProvider, useTheme } from "@emotion/react";
import theme, { Colors } from "./theme/index";
import Home from "./Pages/Home";
import SignIn from "./Pages/SignIn";
import Dashboard from "./Pages/Admin/Dashboard";
import ProtechtedRoute from "./Routes/ProtectedRoute";
import Navbar from "./Components/Navbar";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ViewApplicants from "./Pages/Admin/ViewApplicants";
import Footer from "./Components/Footer";
import HomeNavbar from "./Components/HomeNavbar";
import BlogDetail from "./Pages/BlogDetail";
import ReRegistrationForm from "./Components/ReRegistrationForm";
import ReRegistration from "./Pages/ReRegistration";
import UpdateUdyam from "./Pages/UpdateUdyam";
import PrintUdyam from "./Pages/PrintUdyam";
import CancelUdyam from "./Pages/CancelUdyam";
import UdyogPrint from "./Pages/UdyogPrint";
import SampleCertificate from "./Pages/SampleCertificate";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Blog from "./Pages/Blog";
import TermsConditions from "./Pages/TermsConditions";
import CancellationRefundPolicy from "./Pages/CancellationPolicy";
import Enquiry from "./Pages/Enquiry";
import { blogsApi } from "./Api/Blog";
import { excludeBlogs } from "./Utils/ExcludeBlogs";
import { CleanBlogHeadContent } from "./Utils/CleanBlogHeadContent";

function App() {
  const Layout = () => {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    return (
      <>
        <Navbar
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
        <Box
          sx={{
            mt: { xs: "64px" },
            bgcolor: Colors.background,
          }}
        >
          <Outlet />
          <Footer admin={true} />
        </Box>
      </>
    );
  };
  const SiteLayout = () => {
    return (
      <>
        <HomeNavbar />
        <Box
          sx={{
            mt: { xs: "56px", lg: "64px" },
            bgcolor: Colors.background,
          }}
        >
          <Outlet />
          <Footer />
        </Box>
      </>
    );
  };

  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBlogs = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      if (data) {
        const filteredBlogs = data?.blogs.filter(
          (blog) => excludeBlogs.includes(blog?.title)
        );
        const cleanedBlogs = CleanBlogHeadContent(filteredBlogs);
        setBlogData(cleanedBlogs);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route element={<SiteLayout />}>
              <Route path="/" element={<Home title={'MSME Registration'}/>} />
              <Route path="/blog-details/:id" element={<BlogDetail />} />
              <Route path="/re-registration" element={<ReRegistration title={'Re Registration page'}/>} />
              <Route path="/update-udyam" element={<UpdateUdyam title={'Update Udyam'}/>} />
              <Route path="/print-udyam" element={<PrintUdyam title={'Print Udyam Certificate'}/>} />
              <Route path="/cancel-udyam" element={<CancelUdyam title={'Cancel Udyam Certificate'}/>} />
              <Route path="/udyog-print" element={<UdyogPrint title={'Udyog Print Certificate'}/>} />
              <Route
                path="/sample-certificate"
                element={<SampleCertificate title={'Sample Certificate'}/>}
              />
              <Route path="/contact-us" element={<ContactUs title={'Contact Us'}/>} />
              <Route path="/privacy-policy" element={<PrivacyPolicy title={'Privacy Policy'}/>} />
              <Route path="/terms-conditions" element={<TermsConditions title={'Terms and Conditions'}/>} />
              <Route
                path="/cancellation-policy"
                element={<CancellationRefundPolicy title={'Cancellation Refund Policy'}/>}
              />
              <Route path="/enquiry" element={<Enquiry title={'Enquiry'}/>} />
              <Route path="/blogs" element={<Blog title={'Blogs'}/>} />
            </Route>
            <Route path="/login" element={<SignIn />} />
            <Route element={<ProtechtedRoute allowedRoles={["admin"]} />}>
              <Route element={<Layout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/view-applicants" element={<ViewApplicants />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
