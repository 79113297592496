import { createTheme } from "@mui/material";

export const Colors = {
  primary: "#FF9933", // Teal green for primary actions
  primaryDarker: "#e4811d", // Teal green for primary actions
  secondary: "#07038D", // Amber for secondary actions
  error: "#D32F2F", // Red for error messages
  success: "#388E3C", // Green for success messages
  background: "#F5F5F5", // Light gray for general background
  cardBackground: "#FFFFFF", // White for cards
  textPrimary: "#212121", // Dark gray for main text
  textSecondary: "#4b5563", // Medium gray for secondary text
  sidebarBackground: "#FF9933", // Dark teal for sidebar background
  sidebarActiveItem: "#212121", // Amber for active menu items in the sidebar
  sidebarInactiveItem: "#BDBDBD", // Light gray for inactive menu items
  inputLabel: "#424242", // Dark gray for input labels
  inputBorder: "#BDBDBD", // Light gray for input borders
  inputFocus: "#00796B", // Teal green for focused input borders
  topbarBackground: "#FFFFFF", // White for the top bar
  white: "#FFFFFF", // White for the top bar
  dividerColor: "#07038d33", 
  tableHeaderBorder:'#00796b2e',
  cardHeaderBg:'#616092',
  whiteSecondery:'#e4e4e4',
  formBgColor:'#07038d0a',
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.error,
    },
    success: {
      main: Colors.success,
    },
    background: {
      default: Colors.background,
      paper: Colors.cardBackground,
    },
    text: {
      primary: Colors.textPrimary,
      secondary: Colors.textSecondary,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontSize: "2rem", fontWeight: 700, color: Colors.textPrimary },
    h2: { fontSize: "1.5rem", fontWeight: 700, color: Colors.textPrimary },
    body1: { fontSize: "1rem", color: Colors.textPrimary },
    body2: { fontSize: "0.875rem", color: Colors.textSecondary },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
        },
        containedPrimary: {
          backgroundColor: Colors.primary,
          color: Colors.cardBackground,
          "&:hover": {
            backgroundColor: Colors.primaryDarker, // Darker shade of primary
          },
        },
        containedSecondary: {
          backgroundColor: Colors.secondary,
          color: Colors.textPrimary,
          "&:hover": {
            backgroundColor: "#FFB300", // Darker shade of secondary
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: Colors.white,
          color: Colors.cardBackground,
          "& .MuiListItem-button": {
            color: Colors.sidebarInactiveItem,
          },
          "& .MuiListItem-button.Mui-selected": {
            backgroundColor: Colors.sidebarActiveItem,
            color: Colors.textPrimary,
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      mds: 1000,
      lg: 1200,
      xl: 1536,
      uxl: 2000,
    },
  },
});

export default theme;
