import { Grid, Typography } from "@mui/material";
import React from "react";
import ExampleImg1 from "../../Assets/UdyogPrintCertificate/sample1.webp";
import ExampleImg2 from "../../Assets/UdyogPrintCertificate/CERTIFICATESAMPLENEW2.webp";
import { Colors } from "../../theme";
const SimpleCertificate = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} pl={{ xs: 2, lg: 0 }}>
        <Grid
          item
          spacing={2}
          sx={{ border: `1px solid ${Colors.dividerColor}` }}
        >
          <Grid
            item
            xs={12}
            sx={{ bgcolor: Colors.secondary, p: "8px 16px!important" }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, color: Colors.white }}
              textAlign="start"
            >
              Udyam Registration 2025
            </Typography>
          </Grid>
          <Grid item sx={{ p: 2, backgroundColor: Colors.formBgColor }}>
            <img
              src={ExampleImg1}
              alt="Example img"
              style={{
                width: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} pl={2}>
        <Grid
          item
          spacing={2}
          sx={{ border: `1px solid ${Colors.dividerColor}` }}
        >
          <Grid
            item
            xs={12}
            sx={{ bgcolor: Colors.secondary, p: "8px 16px!important" }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, color: Colors.white }}
              textAlign="start"
            >
              Udyam Registration 2025
            </Typography>
          </Grid>
          <Grid item sx={{ p: 2, backgroundColor: Colors.formBgColor }}>
            <img
              src={ExampleImg2}
              alt="Example img"
              style={{
                width: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimpleCertificate;
