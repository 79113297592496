import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Colors } from "../../theme";

const faqs = [
  {
    question: "What is Msme Registration?",
    answer:
      "Msme Registration is an online platform that assists small and medium enterprises in getting registered and accessing various benefits provided by the government.",
  },
  {
    question: "How can I apply for MSME registration?",
    answer:
      "You can apply for MSME registration through our website by filling out the necessary details and submitting the required documents.",
  },
  {
    question: "What documents are required for MSME registration?",
    answer:
      "The required documents typically include Aadhar card, PAN card, business address proof, and bank details. Specific requirements may vary based on your business type.",
  },
  {
    question: "How long does the MSME registration process take?",
    answer:
      "The registration process usually takes 2-5 working days, depending on government processing times and document verification.",
  },
  {
    question: "Is MSME registration mandatory for all businesses?",
    answer:
      "No, MSME registration is not mandatory, but it provides various benefits such as tax exemptions, lower interest rates, and easier access to loans.",
  },
  {
    question: "Can I update my MSME registration details later?",
    answer:
      "Yes, you can update certain details of your MSME registration, such as business address, contact information, and ownership structure, by applying for modifications through the official portal.",
  },
];

const FAQSection = () => {
  return (
    <Container sx={{ py: 6 }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: Colors.textPrimary,
          mb: 4,
        }}
      >
        Frequently Asked Questions
      </Typography>

      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            mb: 2,
            borderRadius: "4px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07)",
            border: "none",
            backgroundColor: Colors.white,
            "&::before": { backgroundColor: "transparent", opacity: 0 },
            
          }}
        >
          <AccordionSummary
            sx={{
              "&.Mui-expanded": {
                minHeight: "56px",
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: "12px 0",
              },
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{pt:0}}>
            <Typography variant="body1" color={Colors.textSecondary}>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQSection;
