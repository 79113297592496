import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Colors } from "../../theme";
import Joi from "joi";
import { States } from "../../Utils/IndianStates";
import ReCAPTCHA from "react-google-recaptcha";
import { ApplicantsApi } from "../../Api/Applicant";
import { UdyamRegistrationConstants } from "../../Utils/UdyamRegistrationConstants";
import Example1 from "../../Assets/UdyogPrintCertificate/EXAMPLE1.jpg";
import { CaptchaKey } from "../../Utils/CaptchaKey";
const State = States;

const UpdateUdyamCertificate = () => {
  const [formData, setFormData] = useState({
    applicantName: "",
    mobileNumber: "",
    emailId: "",
    additionalInfo: {
      udyamRegistrationNumber: "",
      detailsToBeUpdatedOnCertificate: "",
      state: "",
    },
  });
  const [category, setCategory] = useState("OMNH");
  const [errors, setErrors] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prev) => {
      if (name in prev) {
        // Update root-level fields
        return { ...prev, [name]: newValue };
      } else if (name in prev.additionalInfo) {
        // Update additionalInfo fields
        return {
          ...prev,
          additionalInfo: {
            ...prev.additionalInfo,
            [name]: newValue,
          },
        };
      }
      return prev;
    });

    validateField(name, newValue);
  };
  const validateField = (name, value) => {
    let fieldSchema;

    if (name in formData) {
      // Validate root-level fields (applicantName, mobileNumber, emailId)
      fieldSchema = schema.extract(name);
    } else if (name in formData.additionalInfo) {
      // Validate nested additionalInfo fields
      fieldSchema = schema.extract("additionalInfo").extract(name);
    }

    if (fieldSchema) {
      const { error } = fieldSchema.validate(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error ? error.message : "",
      }));
    }
  };

  const validateFormData = () => {
    const { error } = schema.validate(formData, { abortEarly: false });

    if (error) {
      const newErrors = {};

      error.details.forEach((detail) => {
        let field = detail.path.join(".");
        if (field.startsWith("additionalInfo.")) {
          field = field.replace("additionalInfo.", "");
        }
        newErrors[field] = detail.message;
      });

      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!recaptchaValue) {
    //   alert("Please complete the reCAPTCHA.");
    //   return;
    // }
    if (!validateFormData()) {
      alert("Please add correct information");
      return;
    }
    try {
      const postData = { ...formData, category };
      const res = await ApplicantsApi.addApplicants(postData);
      if (res?.message) {
        alert("Application submitted successfully");
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        alert("Failed to submit the application. Please try again later.");
      }
    } finally {
      setFormData({
        applicantName: "",
        mobileNumber: "",
        emailId: "",
        additionalInfo: {
          udyamRegistrationNumber: "",
          detailsToBeUpdatedOnCertificate: "",
          state: "",
        },
      });
    }
  };

  const schema = Joi.object({
    applicantName: Joi.string()
      .min(3)
      .max(50)
      .required()
      .label("Applicant Name"),
    mobileNumber: Joi.string()
      .pattern(/^[0-9]{10}$/)
      .required()
      .label("Mobile Number")
      .messages({
        "string.pattern.base": "Mobile Number must be exactly 10 digits.",
      }),
    emailId: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email ID"),
    additionalInfo: Joi.object({
      udyamRegistrationNumber: Joi.string()
        .required()
        .label("Udyam Registration Number"),
      detailsToBeUpdatedOnCertificate: Joi.string()
        .allow("")
        .optional()
        .label("Details To Be Updated On Certificate"),
      state: Joi.string().required().label("State"),
    }).required(),
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid
              container
              spacing={2}
              ml={2}
              mt={2}
              sx={{ border: `1px solid ${Colors.dividerColor}` }}
            >
              <Grid
                item
                xs={12}
                sx={{ bgcolor: Colors.secondary, p: "8px 16px!important" }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 700, color: Colors.white }}
                  textAlign="start"
                >
                  Update Udyam Registration Certificate Online
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ p: 2, backgroundColor: Colors.formBgColor, ml: 0, pl: 0 }}
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">1. Name of Owner*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Applicant Name"
                    name="applicantName"
                    value={formData.applicantName}
                    onChange={handleChange}
                    error={!!errors?.applicantName}
                    helperText={
                      errors?.applicantName ||
                      UdyamRegistrationConstants?.applicantName
                    }
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">2. Mobile Number*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    error={!!errors?.mobileNumber}
                    helperText={
                      errors?.mobileNumber ||
                      UdyamRegistrationConstants?.mobileNumber
                    }
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">3. E-mail ID*</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Email ID"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    error={!!errors?.emailId}
                    helperText={
                      errors?.emailId || UdyamRegistrationConstants?.emailId
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    4. Udyam Registration Number*
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Udyam Registration Number"
                    name="udyamRegistrationNumber"
                    value={formData.udyamRegistrationNumber}
                    onChange={handleChange}
                    error={!!errors?.udyamRegistrationNumber}
                    helperText={
                      errors?.udyamRegistrationNumber ||
                      UdyamRegistrationConstants?.udyamRegistrationNumber
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">5. State*</Typography>

                  <FormControl size="small" fullWidth>
                    <Select
                      name="state"
                      value={formData?.additionalInfo?.state}
                      onChange={handleChange}
                      error={!!errors?.state}
                      required
                      displayEmpty
                    >
                      <MenuItem value="">Select State</MenuItem>
                      {State?.map((stateObj, i) => (
                        <MenuItem key={stateObj.state} value={stateObj.state}>
                          {`${i + 1} ${stateObj.state}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors?.state ? (
                    <FormHelperText error>{errors?.state}</FormHelperText>
                  ) : (
                    <FormHelperText>
                      {UdyamRegistrationConstants?.state}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">3. E-mail ID*</Typography>
                  <Typography display={"block"} color="error" variant="caption">
                    KYC DETAILS, PAN Number, State And District Cannot be
                    Updated
                  </Typography>
                  <TextField
                    sx={{ mt: 1 }}
                    type="text"
                    size="small"
                    fullWidth
                    placeholder="Details To Be Updated On Certificate"
                    name="detailsToBeUpdatedOnCertificate"
                    value={
                      formData?.additionalInfo?.detailsToBeUpdatedOnCertificate
                    }
                    onChange={handleChange}
                    error={!!errors?.detailsToBeUpdatedOnCertificate}
                    helperText={
                      errors?.detailsToBeUpdatedOnCertificate ||
                      UdyamRegistrationConstants.detailsToBeUpdatedOnCertificate
                    }
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </form>
      </Grid>
      <Grid item xs={12} sm={6} pl={2}>
        <Grid
          item
          spacing={2}
          sx={{ border: `1px solid ${Colors.dividerColor}` }}
        >
          <Grid
            item
            xs={12}
            sx={{ bgcolor: Colors.secondary, p: "8px 16px!important" }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, color: Colors.white }}
              textAlign="start"
            >
              Update Udyam Registration Certificate Online 2025
            </Typography>
          </Grid>
          <Grid item sx={{ p: 2 }}>
            <img
              src={Example1}
              alt="Udyam Registration"
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateUdyamCertificate;
