import { Box, Container, Typography, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import banner from "../../Assets/Banner/banner1.webp";
import { Colors } from "../../theme";
import { Helmet } from "react-helmet";
import { blogsApi } from "../../Api/Blog";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";

const PrivacyPolicy = ({ title, description }) => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBlog = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      let blog = data?.blogs?.find((item) => item.title === "PRIVACY_POLICY");
      const blogData = cleanBlogContent(blog);
      setBlogData(blogData || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <Box
      sx={{
        margin: "0 auto",
        bgcolor: Colors.white,
      }}
    >
      {loading ? (
        <></>
      ) : (
        <Helmet>
          <title>
            {blogData?.content?.split("(")[0].replace(/-/g, "").trim() ||
              "Privacy Policy11"}
          </title>
          <meta
            name="description"
            content={
              blogData?.content?.split("(")[1]?.replace(")", "").trim() ||
              "description"
            }
          />
        </Helmet>
      )}
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "300px",
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
            zIndex: 1,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: Colors.white,
            position: "relative",
            zIndex: 3,
          }}
        >
          Privacy Policy
        </Typography>
      </Box>

      <Container maxWidth="lg" sx={{ py: 6, bgcolor: Colors.white }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Privacy Policy for MSME Registration
        </Typography>
        <Typography
          variant="body1"
          color={Colors.textSecondary}
          sx={{ letterSpacing: "0.5px" }}
          mb={4}
        >
          At MSMERegistration, accessible from
          https://MSMERegistration.org/privacy-policy, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by MSMERegistration and how we use it. If you have additional
          questions or require more information about our Privacy Policy, do not
          hesitate to contact us at order@MSMERegistration.org This Privacy
          Policy applies only to our online activities and is valid for visitors
          to our website with regards to the information that they shared and/or
          collect in MSMERegistration This policy is not applicable to any
          information collected offline or via channels other than this website.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Consent
        </Typography>
        <Typography
          variant="body1"
          color={Colors.textSecondary}
          sx={{ letterSpacing: "0.5px" }}
          mb={4}
        >
          By using our website, you hereby consent to our Privacy Policy , SOP
          (Standard Operating Procedure) and agree to its terms.
        </Typography>

        <List sx={{ listStyleType: "none", padding: 0, mb: 4 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The term 'You' and 'User' shall be used for any legal person or
              entity accessing the services of this website.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The terms 'We', 'Our', & 'Us' shall be used for the website and
              company as per the required context.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The terms 'party' and 'parties' respectively shall be used to
              represent the user and the company collectively and individually,
              as per the context.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The headings in each section of this privacy policy are given to
              organize the information in an orderly manner.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The users solely agree to the privacy policy and terms and
              conditions as they visit the website or avail of any service
              provided by the website.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user acknowledges that this policy and terms are a legally
              binding agreement between the user and the company, and the user
              shall be subject to the policies, guidelines, terms, and
              conditions. The user understands and agrees that no Signature is
              required for these terms and policies to be binding on the user.
              The user visiting any part of the website would signify the full
              and final approval of the policy and terms mentioned.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              Both the parties agree that the company will have the sole right
              to make any amendments to the policy and terms without needing any
              prior approval from the user. The user is responsible for checking
              the policy from time to time and staying updated. As far as the
              users comply with the policy and terms, they will be granted the
              personal and limited privilege to use and access the website.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Collecting Personal Information
        </Typography>
        <List sx={{ listStyleType: "none", padding: 0, mb: 4 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The User agrees that the company can collect and store the user's
              data that the user provides on the website. The user acknowledges
              that this information can be used by the website to provide
              targeted services to meet users' needs and improve user
              experience.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The users agree that the company can use their feedback/ reviews/
              messages and store them indefinitely. That stored information can
              be used by the company to resolve discrepancies, troubleshoot
              issues, provide customer care, and provide it to judicial or
              government bodies (if requested).
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user can browse the website without being registered, but
              activities like placing orders require the user to provide
              personal information to the website for registration. So, the User
              hereby consents that this contact information can be used for
              promotional purposes by the website.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user is aware of the optional online surveys conducted on the
              website that may require the user to provide some personal
              information.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              Moreover, the user is aware that the company may request the user
              to write product/ service reviews so that other users can be
              benefited when they visit the website. Thus, the user gives the
              website the authority to publish these reviews along with the
              user's name and contact.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user agrees that the website can remove any of the user's
              reviews, feedback, survey, or anything without a prior need for
              informing the user.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Website Cookies
        </Typography>
        <List sx={{ listStyleType: "none", padding: 0, mb: 4 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user understands that 'cookies' are the pieces of information
              stored on a web browser to remember user-specific information. The
              user consents that the website places permanent and temporary
              cookies in the user's hard drive and web browser.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              Furthermore, the user is aware that the website uses cookies on
              certain pages to analyze their effectiveness and certain features
              of websites are accessible only through these cookies. The user is
              free to decline the cookies but he/ she may be unable to use
              certain features of the website.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user is aware that he/she might see cookies from third-party
              or affiliate websites on certain pages of the website. So, the
              user acknowledges that the website is not responsible for or
              controls such cookies from third-party websites and related risks.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Sharing Personal Info
        </Typography>
        <List sx={{ listStyleType: "none", padding: 0, mb: 4 }}>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user acknowledges that the company can share some of the
              user's personal information with other entities to prevent
              identity theft and misuse of website services.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" color={Colors.textSecondary}>
              The user is aware that the website can share personal information
              if it is necessary and required by the law, or if the third
              party's rights are violated by any of the advertisements or
              postings.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Secure Payment
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary} sx={{ mb: 4 }}>
          Any transaction on the website is secure, encrypted, and protected
          from third parties. The user's credit and debit card details are not
          received, stored, or used by the company in any way.
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            lineHeight: 1.3,
          }}
          color={Colors.textPrimary}
          mb={1}
        >
          Third-Party Promotions
        </Typography>
        <Typography variant="body1" color={Colors.textSecondary}>
          The user is aware that third-party ads are displayed on the company's
          website and user information might be tracked by third-party websites
          to provide customized ads. The website may contain links to other
          websites which might collect user information. So, the user is solely
          responsible for any risks associated with that.
        </Typography>

        <Typography
          variant="body1"
          color={Colors.textSecondary}
          sx={{ letterSpacing: "0.5px", mt: 2 }}
        >
          The parties agree to the policy and agreements mentioned above, and we
          keep users' information confidential at all levels of our company.
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
