import React, { useEffect, useState } from "react";
import ContactDetails from "../../Components/ContactDetails";
import MapDetailsSection from "../../Components/MapDetailsSection";
import { Helmet } from "react-helmet";
import { blogsApi } from "../../Api/Blog";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";

const ContactUs = ({title, description}) => {
   const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchBlog = async () => {
      setLoading(true);
  
      try {
        const data = await blogsApi.getAllBlogs();
        let blog = data?.blogs.find((item) => item.title === "CONTACT_US");
        const blogData = cleanBlogContent(blog);
        setBlogData(blogData || {});
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
      fetchBlog();
    }, []);
  return (
    <div>
       {loading ? (
             <></>
           ) : (
             <Helmet>
               <title>{blogData?.content?.split("(")[0].replace(/-/g, "").trim() || "Contact Us"}</title>
               <meta name="description" content={blogData?.content?.split("(")[1]?.replace(")", "").trim() || "description"} />
             </Helmet>
           )}
      <ContactDetails />
      <MapDetailsSection />
    </div>
  );
};

export default ContactUs;
