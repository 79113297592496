export const cleanBlogContent = (blog) => {
    if (!blog) return {};
  
    const cleanedContent = blog.content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
  
    const formattedTitle = blog.title
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/\b\w/g, char => char.toUpperCase());
  
    return {
      content: cleanedContent,
      title: formattedTitle,
    };
  };
  