export const excludeBlogs = [
  "HOME_PAGE",
  "UDYAM_REGISTRATION_FORM",
  "UDYAM_RE_REGISTRATION",
  "UPDATE_UDYAM_CERTIFICATE",
  "PRINT_UDYAM_CERTIFICATE",
  "CANCEL_UDYAM_REGISTRATION",
  "UDYOG_PRINT_CERTIFICATE",
  "SAMPLE_CERTIFICATE",
  "CANCELLATION_REFUND_POLICY",
  "PRIVACY_POLICY",
  "TERMS_CONDITIONS",
  "ENQUIRY_PAGE",
  "CONTACT_US",
  "BLOGS_PAGE",
];
