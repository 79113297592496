import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Colors } from "../../theme";

const MapDetails = ({
  title,
  description,
  mapLocationSrc,
  orderValue,
  orderValue2,
  last,
}) => {
  return (
    <Box
      sx={{
        mb: last ? 8 : 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems="center">
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              order: { xs: 1, md: orderValue },
            }}
          >
            <Box
              component="iframe"
              src={mapLocationSrc}
              width="100%"
              height="350"
              style={{
                border: 0,
              }}
              allowFullScreen
              aria-hidden="false"
              tabIndex="0"
            />
          </Grid>
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              order: { xs: 2, md: orderValue2 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h5"
              component="h3"
              sx={{
                mb: 1,
                fontWeight: "bold",
                color: Colors.textPrimary,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const MapDetailsSection = () => {
  return (
    <Box sx={{ px: 0, py: 4 }}>
      <MapDetails
        title="MSME Office - Headquarters"
        description="Udyog Bhawan, Rafi Marg, New Delhi, India 110011"
        mapLocationSrc="https://www.google.com/maps/embed?pb=PLACEHOLDER"
        orderValue={1}
        orderValue2={2}
      />
      <MapDetails
        title="MSME Office - Regional Office (South)"
        description="Small Industries Bhavan, 65/1 Gandhi Nagar, Bangalore, India 560009"
        mapLocationSrc="https://www.google.com/maps/embed?pb=PLACEHOLDER"
        orderValue={2}
        orderValue2={1}
      />
    </Box>
  );
};

export default MapDetailsSection;
