import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../theme";
import EnquiryForm from "../../Components/EnquiryForm";
import { Helmet } from "react-helmet";
import { blogsApi } from "../../Api/Blog";
import { cleanBlogContent } from "../../Utils/cleanBlogContent";

const Enquiry = ({ title, description }) => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchBlog = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      let blog = data?.blogs.find((item) => item.title === "ENQUIRY_PAGE");
      const blogData = cleanBlogContent(blog);
      setBlogData(blogData || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, []);
  return (
    <Box
      sx={{
        margin: "0 auto",
        bgcolor: Colors.white,
      }}
    >
      {loading ? (
        <></>
      ) : (
        <Helmet>
          <title>
            {blogData?.content?.split("(")[0].replace(/-/g, "").trim() ||
              "Enquiry"}
          </title>
          <meta
            name="description"
            content={
              blogData?.content?.split("(")[1]?.replace(")", "").trim() ||
              "description"
            }
          />
        </Helmet>
      )}

      <Container maxWidth="lg" sx={{ py: 6, bgcolor: Colors.white }}>
        <EnquiryForm />
      </Container>
    </Box>
  );
};

export default Enquiry;
