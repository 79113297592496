import React, { useEffect, useState } from "react";
import { Colors } from "../../../theme";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import TextEditor from "../../../Utils/TextEditor";
import { blogsApi } from "../../../Api/Blog";
import { excludeBlogs } from "../../../Utils/ExcludeBlogs";

const Dashboard = () => {
  const [title, setTitle] = useState("");
  const [textValue, setTextValue] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [blogData, setBlogData] = useState([]);
  const [pagesData, setPagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handlSubmit = async () => {
    if (!title.trim() || !textValue.trim()) {
      alert("Title and content are required");
      return;
    }
    const postData = {
      title,
      content: textValue,
    };
    const payloadSize = new TextEncoder().encode(
      JSON.stringify(postData)
    ).length;

    const MAX_PAYLOAD_SIZE = 1 * 1024 * 1024;
    if (payloadSize > MAX_PAYLOAD_SIZE) {
      alert(
        "The data you are trying to save is too large. Please reduce the content size."
      );
      return;
    }

    if (editIndex !== null) {
      try {
        const data = await blogsApi.updateBlog(editIndex, postData);
        if (data) {
          setEditIndex(null);
          alert("Blog updated successfully!");
          fetchBlogs();
        }
      } catch (err) {
        console.error("Error updating food menu:", err);
      } finally {
        setTextValue("");
        setTitle("");
      }
    } else {
      const postData = {
        title,
        content: textValue,
      };
      const payloadSize = new TextEncoder().encode(
        JSON.stringify(postData)
      ).length;

      const MAX_PAYLOAD_SIZE = 1 * 1024 * 1024;
      if (payloadSize > MAX_PAYLOAD_SIZE) {
        alert(
          "The data you are trying to save is too large. Please reduce the content size."
        );
        return;
      }
      setLoadingSubmit(true);
      try {
        const data = await blogsApi.createBlog(postData);
        alert(data.message);

        setBlogData((prevData) => [data?.blog, ...prevData]);
      } catch (err) {
      } finally {
        setLoadingSubmit(false);
        setTextValue("");
        setTitle("");
      }
    }
  };

  const handleEditBlog = (id, item) => {
    setTitle(item?.title);
    setTextValue(item?.content);
    setEditIndex(id);
  };

  const handleDeleteBlog = async (id) => {
    try {
      const data = await blogsApi.deleteBlog(id);
      setBlogData((prevData) => prevData.filter((item) => item.id !== id));
      alert(data?.message);
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const fetchBlogs = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      if (data) {
        const pages = data?.blogs?.filter((item) =>
          excludeBlogs.includes(item.title)
        );
        const blogs = data?.blogs?.filter(
          (item) => !excludeBlogs.includes(item.title)
        );
        console.log(pages, "pages");
        console.log(blogs, "blogs");
        setPagesData(pages);
        setBlogData(blogs);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);
  return (
    <Box
      sx={{
        bgcolor: Colors.background,
        minHeight: "100dvh",
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          alignItems: "start",
          justifyContent: "flex-start",
          flexGrow: 1,
          px: { xs: 2 },
          py: { xs: 2 },
        }}
      >
        <Grid container spacing={2} mt={1}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              textAlign: "left",
              alignContent: "start",
              mb: { xs: 4, md: 0 },
              pr: 2,
              pt: 0,
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "200px",
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Grid container spacing={2} pt={0}>
                <Grid item xs={12} mb={2} sx={{pt:'0px!important'}}>
                  <Typography
                    className={"poppins-font"}
                    fontWeight={"500"}
                    variant="h6"
                    color={Colors.body2}
                  >
                    Created Pages
                  </Typography>
                </Grid>
                {pagesData?.map((item, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={i}
                      sx={{ p: "0px 0px 16px 16px!important" }}
                    >
                      <Card
                        sx={{
                          backgroundColor: Colors.white,
                          borderRadius: "12px",
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
                          transition: "transform 0.2s, box-shadow 0.2s",
                          "&:hover": {
                            transform: "scale(1.02)",
                            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.04)",
                          },
                          maxWidth: {
                            xs: "300px",
                            sm: "400px",
                            mds: "500px",
                            lg: "400px",
                          },
                        }}
                      >
                        <CardContent sx={{ p: "16px 16px 8px 16px!important" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              mb: 1,
                            }}
                          >
                            <Typography
                              variant="h6"
                              color="text.primary"
                              sx={{
                                textTransform: "capitalize",
                                wordBreak: "break-word",
                              }}
                            >
                              {item?.title}
                            </Typography>
                          </Box>
                          <TextEditor displayValue={item?.content || ""} />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Button
                              variant="text"
                              sx={{
                                color: Colors.primary,
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleEditBlog(item?.id, item)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="text"
                              sx={{
                                color: Colors.error,
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleDeleteBlog(item?.id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
                <Divider sx={{borderColor:Colors.dividerColor,ml:2,width:'-webkit-fill-available'}}/>
                <Grid item xs={12} mb={2}>
                  <Typography
                    className={"poppins-font"}
                    fontWeight={"500"}
                    variant="h6"
                    color={Colors.body2}
                  >
                    Created Blogs
                  </Typography>
                </Grid>
                {blogData?.map((item, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      key={i}
                      sx={{ p: "0px 0px 16px 16px!important" }}
                    >
                      <Card
                        sx={{
                          backgroundColor: Colors.white,
                          borderRadius: "12px",
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
                          transition: "transform 0.2s, box-shadow 0.2s",
                          "&:hover": {
                            transform: "scale(1.02)",
                            boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.04)",
                          },
                          maxWidth: {
                            xs: "300px",
                            sm: "400px",
                            mds: "500px",
                            lg: "400px",
                          },
                        }}
                      >
                        <CardContent sx={{ p: "16px 16px 8px 16px!important" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              mb: 1,
                            }}
                          >
                            <Typography
                              variant="h6"
                              color="text.primary"
                              sx={{
                                textTransform: "capitalize",
                                wordBreak: "break-word",
                              }}
                            >
                              {item?.title}
                            </Typography>
                          </Box>
                          <TextEditor displayValue={item?.content || ""} />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Button
                              variant="text"
                              sx={{
                                color: Colors.primary,
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleEditBlog(item?.id, item)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="text"
                              sx={{
                                color: Colors.error,
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleDeleteBlog(item?.id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              backgroundColor: Colors.white,
              boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.03)",
              borderRadius: "12px",

              textAlign: "left",
              alignContent: "start",
              mb: { xs: 4, md: 0 },
              p: 2,
              height: "fit-content",
            }}
          >
            <Typography
              className={"poppins-font"}
              fontWeight={"500"}
              variant="h6"
              color={Colors.body2}
            >
              Create Blog
            </Typography>
            <Grid container mt={2}>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  label="Tile"
                  variant="outlined"
                  size="small"
                  name="title"
                  value={title}
                  onChange={handleChangeTitle}
                />
              </Grid>
              <Grid xs={12} mt={2}>
                <TextEditor
                  textValue={textValue}
                  setTextValue={setTextValue}
                  imageOption={true}
                />
              </Grid>
              <Grid xs={12} mt={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handlSubmit}
                  disabled={loadingSubmit}
                >
                  {editIndex !== null ? "Update Blog" : "Create Blog"}
                  {loadingSubmit && (
                    <CircularProgress
                      color="primary"
                      size={24}
                      sx={{ ml: 1 }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
