import { Box, Container, Typography } from "@mui/material";
import React from "react";
import HeroImg from "../../Assets/Banner/msme_registration_banner_image.webp";
import { Colors } from "../../theme";

const HeroSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${HeroImg})`,
        backgroundSize: "cover",
        backgroundPositionY: "top",
        backgroundPosition: { xs: "left", md: "right" },
        backgroundRepeat: "no-repeat",
        height:{xs:'50dvh',sm: "80dvh"},
        display: "flex",
        alignItems: "center",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: {
            xs: "rgba(0, 0, 0, 0.7)",
            md: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)",
          },
          zIndex: 1,
        },
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          px: { xs: 2 },
          py: { xs: 4, lg: 2 },
        }}
      >
        <Box sx={{ maxWidth: { xs: "100%", lg: "60%!important" },textAlign:'center' }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "600",
              fontSize: { xs: "24px", sm:'36px', md: "48px" },
              lineHeight: 1.3,
              textAlign:'center'
            }}
            color={Colors.white}
            mb={2}
          >
           Udyam Registration 2025
          </Typography>
          <Typography
            variant="h6"
            color={Colors.white}
            sx={{ letterSpacing: "0.5px",fontSize:{xs:"10px",sm:'14px',md:'16px'} }}
            mb={2}
          >
            Empowering Growth for Micro, Small & Medium Enterprises
            <br />
            (उद्यम पंजीकरण प्रमाणपत्र: सूक्ष्म, लघु और मध्यम उद्यमों के लिए)
          </Typography>
          <Typography
            variant="h6"
            color={Colors.white}
            sx={{ letterSpacing: "0.5px",fontSize:{xs:"10px",sm:'14px',md:'16px'}  }}
            mb={2}
          >
            Let us work towards making Atmanirbhar Bharat Abhiyan a Success!
            <br />
            (आइए आत्मनिर्भर भारत अभियान को सफल बनाने का प्रयास करें!)
          </Typography>
        </Box>
        {/* <Box
          sx={{
            flexGrow: 1,
            flexBasis: "40%",
          }}
        />

        <Box sx={{ flexGrow: 1 }} /> */}
      </Container>
    </Box>
  );
};

export default HeroSection;
