import React, { useState } from "react";
import { Box, Container, Typography, TextField, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid2";
import banner from "../../Assets/Banner/banner1.webp";
import contactImg from "../../Assets/contactUs/contactImg.webp";
import { Colors } from "../../theme";
const ContactDetails = () => {
 
  return (
    <Box sx={{ backgroundColor: Colors.background, pb: 6, }}>
      <Box
        sx={{
          width: "100%",
          height: "300px",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            height: "300px",
            width: "100%",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
            zIndex: 1,
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            color: Colors.white,
            position: "relative",
            zIndex: 3,
          }}
        >
          Let's Discuss Something Cool Together
        </Typography>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }} sx={{ mt: 3 }}>
            <Typography variant="h5" sx={{ color: Colors.textPrimary}}>
              Contact Details
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <Box
              sx={{
                mb: 2,
                p: 3,
                borderRadius: "8px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: Colors.white,
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
              For Udyam Registration:
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 1, color: Colors.textSecondary }}
              >
                Feel free to contact us between 8:00 am and 8:00 pm
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <EmailIcon sx={{ mr: 1 }} /> Udyamcareinfo@gmail.com
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <PhoneIcon sx={{ mr: 1 }} />
                +91 7209843655
              </Typography>
            </Box>
            <Box
              sx={{
                p: 3,
                borderRadius: "8px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: Colors.white,
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
              For Udyog Registration:
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 1, color: Colors.textSecondary }}
              >
                Feel free to call us between 10:00 am to 10:00 pm
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <EmailIcon sx={{ mr: 1 }} /> udyamcareinfo@gmail.com
              </Typography>
              <Typography
                variant="body1"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <PhoneIcon sx={{ mr: 1 }} />
                +91 7209843655, +91 7209843655
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, md: 7 }}>
            <Box
              component="img"
              src={contactImg}
              alt="Team collaboration"
              sx={{
                borderRadius: "8px",
                width: "100%",
                maxHeight: "360px",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactDetails;
