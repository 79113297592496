import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../theme";
import TextEditor from "../../Utils/TextEditor";
import { useParams } from "react-router-dom";
import { blogsApi } from "../../Api/Blog";
import { parseTitleAndDescription } from "../../Utils/parseTitleAndDescription";
import { Helmet } from "react-helmet";

const BlogDetail = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [extractedTitle, setExtractedTitle] = useState(null);
  const [extractedDescription, setExtractedDescription] = useState(null);
  const { id } = useParams();
  const fetchBlog = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      let blog = data?.blogs.find((item) => item.id === id);
      setBlogData(blog || {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlog();
  }, [id]);

  useEffect(() => {
    if (blogData) {
      const { extractedTitle, extractedDescription } = parseTitleAndDescription(
        blogData?.title
      );
      setExtractedTitle(extractedTitle);
      setExtractedDescription(extractedDescription);
    }
  }, [blogData]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "200px",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Helmet>
            <title>
              {blogData?.title?.split("(")[0].replace(/-/g, "").trim()||'Blog'}
            </title>
            <meta
              name="description"
              content={blogData?.title?.split("(")[1]?.replace(")", "").trim()}
            />
          </Helmet>

          <Container maxWidth="lg" sx={{ py: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <Card
                  sx={{
                    backgroundColor: Colors.white,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                      boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <CardContent sx={{ p: 2 }}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{
                        textTransform: "capitalize",
                        wordBreak: "break-word",
                        lineHeight: 1.5,
                      }}
                    >
                      {extractedTitle || blogData?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 0.5, fontSize: "0.875rem" }}
                    >
                      Published on:{" "}
                      {new Date(blogData?.created_at).toLocaleDateString()}
                    </Typography>
                    <TextEditor displayValue={blogData?.content || ""} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default BlogDetail;
