import React, { useEffect, useState } from "react";
import { blogsApi } from "../../Api/Blog";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import TextEditor from "../../Utils/TextEditor";
import { Colors } from "../../theme";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { excludeBlogs } from "../../Utils/ExcludeBlogs";
import { parseTitleAndDescription } from "../../Utils/parseTitleAndDescription";
const BlogSection = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchBlogs = async () => {
    setLoading(true);

    try {
      const data = await blogsApi.getAllBlogs();
      if (data) {
        const filteredBlogs = data.blogs.filter((item) => {
          const { extractedTitle } = parseTitleAndDescription(item?.title);
          return !excludeBlogs.includes(extractedTitle);
        });
        setBlogData(filteredBlogs?.slice(0, 3));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const excludeTitles = excludeBlogs;
  return (
    <Box
      sx={{
        bgcolor: Colors.background,
        py: 3,
        minHeight: "fit-content",
      }}
    >
      <Container
        sx={{
          px: { xs: 2 },
        }}
      >
        <Box sx={{ width: "100%", mb: 3 }}>
          <Typography
            variant="h4"
            color={Colors.primary}
            sx={{
              textAlign: "start",
              fontWeight: "bold",
              textTransform: "capitalize",
              pl: 2,
            }}
          >
            Latest Blogs
          </Typography>
        </Box>

        <Grid container spacing={2} pt={0} mt={1}>
          {blogData?.map((item, i) => {
            const { extractedTitle } = parseTitleAndDescription(item?.title);
            return (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={i}
                sx={{ p: "0px 0px 16px 16px!important" }}
              >
                <Card
                  key={i}
                  sx={{
                    backgroundColor: Colors.white,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.04)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                      boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <CardContent sx={{ p: 2 }}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{
                        textTransform: "capitalize",
                        wordBreak: "break-word",
                        lineHeight: 1.5,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                      }}
                    >
                      {extractedTitle || item?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 0.5, fontSize: "0.875rem" }}
                    >
                      Published on:{" "}
                      {new Date(item?.created_at).toLocaleDateString()}
                    </Typography>
                    <TextEditor
                      displayValue={item?.content || ""}
                      isHomePage={true}
                    />
                    <Button
                      size="small"
                      onClick={() => navigate(`/blog-details/${item?.id}`)}
                    >
                      Read More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogSection;
