import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import BannerImageSigninSingUp from "../../Assets/UdyogPrintCertificate/redd-francisco-5U_28ojjgms-unsplash.jpg";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userAuthAPI } from "../../Api/UserAuth/index";
import { jwtDecode } from "jwt-decode";
import { Colors } from "../../theme";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const Schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.email": "Invalid email address",
        "string.empty": "Email is required",
      }),
    password: Joi.string().min(3).required().messages({
      "string.min": "Password must be at least 3 characters long",
      "string.empty": "Password is required",
    }),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const validateField = (name, value) => {
    const fieldSchema = Schema.extract(name);
    const { error } = fieldSchema.validate(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error ? error.message : "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const navigate = useNavigate();

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleSubmit = async () => {
    const { error } = Schema.validate(formData, { abortEarly: false });
    if (error) {
      const fieldErrors = error.details.reduce((acc, curr) => {
        acc[curr.path[0]] = curr.message;
        return acc;
      }, {});
      setErrors(fieldErrors);
    } else {
      setLoading(true);
      setErrors({});
      try {
        const data = await userAuthAPI.login(formData);
        const token = data?.token;
        localStorage.setItem("Token", token);
        localStorage.setItem("Role", data?.user?.role);
        localStorage.setItem("user", JSON.stringify(data?.user));
        navigate("/dashboard");
      } catch (err) {
        if (err?.response?.status === 401) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            message: err?.response?.data?.message,
          }));
        }
        alert(err?.response?.data?.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        m: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.background,
        minHeight: "100dvh",
        p: { xs: 1.5, sm: 0 },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: Colors.white,
          borderRadius: "12px",
          boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.03)",
        }}
        size={{ xs: 12, sm: 10, md: 6 }}
      >
        <Grid
          item
          size={{ xs: 12, sm: 8, md: 8 }}
          sx={{
            py: { xs: 3, sm: 4 },
            px: { xs: 2, sm: 4 },
            pb: { xs: 2, sm: 3 },
            order: { xs: 2, sm: 1 },
          }}
        >
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(errors?.email)}
                helperText={errors?.email}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                size="small"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={Boolean(errors?.password)}
                helperText={errors?.password}
                InputProps={
                  ({ textAlign: "center" },
                  {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  })
                }
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              {errors?.message && (
                <Typography sx={{ fontSize: "12px", color: Colors.error }}>
                  {errors?.message}
                </Typography>
              )}
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button
                variant="contained"
                sx={{ borderRadius: 1 }}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="primary" size={"24px"} />
                ) : (
                  " Sign In"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          size={{ xs: 12, sm: 4, md: 4 }}
          sx={{
            py: { xs: 3, sm: 4 },
            px: { xs: 2, sm: 4 },
            pb: { xs: 2, sm: 3 },
            backgroundImage: `linear-gradient( rgba(255, 153, 51, 0.9), rgba(255, 153, 51, 0.2)),url(${BannerImageSigninSingUp})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backdropFilter: "blur(8px)",
            borderRadius: { xs: "12px 12px 0px 0px", sm: "0px 12px 12px 0px" },
            order: { xs: 1, sm: 2 },
          }}
        >
          <Typography variant="h6" color={Colors.white} mb={1}>
            Log In
          </Typography>
          <Typography
            variant="body2"
            color={Colors.white}
            sx={{ lineHeight: "1.6" }}
            mb={2}
          >
            Manage blogs applicants and more with admin panel
          </Typography>
          <Typography
            variant="body2"
            color={Colors.white}
            sx={{ lineHeight: "1.6" }}
          >
            Login, Now.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignIn;
