import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Colors } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Branding/navLogo.webp";
export default function Footer({ admin = false }) {
  const navigate = useNavigate();

  const quickLinks = [
    { label: "Blogs", path: "/blogs" },
    { label: "Contact Us", path: "/contact-us" },
    { label: "Enquiry", path: "/enquiry" },
  ];

  const policies = [
    { label: "Terms & Conditions", path: "/terms-conditions" },
    { label: "Privacy Policy", path: "/privacy-policy" },
    { label: "Cancellation Policy", path: "/cancellation-policy" },
  ];
  return (
    <>
      {admin ? (
        <Box
          component="footer"
          sx={{ backgroundColor: Colors.secondary, py: 3 }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="body2"
              color="white"
              align="center"
              sx={{ opacity: 0.9 }}
            >
              © {new Date().getFullYear()} MSME Registration Portal. All rights
              reserved.
            </Typography>
          </Container>
        </Box>
      ) : (
        <>
          <Box
            component="footer"
            sx={{ backgroundColor: Colors.secondary, py: 5 }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <Box
                    component="img"
                    src={Logo}
                    alt="Company Logo"
                    sx={{ width: "80px", height: "80px", mb: 2,borderRadius:1 }}
                  />
                  <Typography variant="body2" color={Colors.white}>
                    Your trusted partner for MSME solutions. We help businesses
                    grow with our expert services and support.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="h6" sx={{ mb: 1, color: Colors.white }}>
                    Quick Links
                  </Typography>
                  <List>
                    {quickLinks.map((link, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <ListItemText
                          primary={link.label}
                          onClick={() => navigate(link.path)}
                          sx={{ cursor: "pointer" }}
                          primaryTypographyProps={{
                            color: Colors.whiteSecondery,
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="h6" sx={{ mb: 1, color: Colors.white }}>
                    Policies
                  </Typography>
                  <List>
                    {policies.map((policy, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <Link
                          to={policy.path}
                          style={{ textDecoration: "none" }}
                        >
                          <ListItemText
                            primary={policy.label}
                            primaryTypographyProps={{
                              color: Colors.whiteSecondery,
                            }}
                          />
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: Colors.secondary,
              py: 2,
              borderTop: `1px solid ${Colors.whiteSecondery}`,
            }}
          >
            <Typography variant="body2" color="white">
              © {new Date().getFullYear()} MSME Registration Portal. All rights
              reserved.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
